import service from '@/utils/request';

//创建文件
export var createFile = function createFile(data) {
  return service({
    url: "/fileService/createFile",
    method: 'post',
    data: data
  });
};

//AddFolderUser
export var addFolderUser = function addFolderUser(data) {
  return service({
    url: "/fileService/addFolderUser",
    method: 'post',
    data: data
  });
};

//addFolderGroup
export var addFolderGroup = function addFolderGroup(data) {
  return service({
    url: "/fileService/addFolderGroup",
    method: 'post',
    data: data
  });
};
//getUserByNotInFolderId
export var getUserByNotInFolderId = function getUserByNotInFolderId(data) {
  return service({
    url: "/fileService/getUserByNotInFolderId",
    method: 'post',
    data: data
  });
};

//GetUserByFolderId
export var getUserByFolderId = function getUserByFolderId(data) {
  return service({
    url: "/fileService/getUserByFolderId",
    method: 'post',
    data: data
  });
};

//getFolderByGroupId
export var getFolderByGroupId = function getFolderByGroupId(data) {
  return service({
    url: "/fileService/getFolderByGroupId",
    method: 'post',
    data: data
  });
};

//getNewSignedS3Url
export var getNewSignedS3Url = function getNewSignedS3Url(params) {
  return service({
    url: "/fileService/getNewSignedS3Url",
    method: 'get',
    params: params
  });
};

//getFileList
export var getFileList = function getFileList(params) {
  return service({
    url: "/fileService/getFileList",
    method: 'get',
    params: params
  });
};
export var deleteFile = function deleteFile(data) {
  return service({
    url: "/fileService/deleteFile",
    method: 'delete',
    data: data
  });
};

//DeleteFiles
export var deleteFiles = function deleteFiles(data) {
  return service({
    url: "/fileService/deleteFiles",
    method: 'delete',
    data: data
  });
};

//上传文件
export var uploadFile = function uploadFile(data) {
  return service({
    url: "/fileService/uploadFile",
    method: 'post',
    data: data
  });
};

//校验文件是否存在
export var validFileExists = function validFileExists(data) {
  return service({
    url: "/fileService/validFileExists",
    method: 'post',
    data: data
  });
};

// 下载文件
export var downloadFile = function downloadFile(data) {
  return service({
    url: "/fileService/downloadFiles",
    method: 'post',
    data: data,
    responseType: 'blob'
  });
};

// getAllFolders
export var getAllFolders = function getAllFolders(params) {
  return service({
    url: "/fileService/getAllFolders",
    method: 'get',
    params: params
  });
};