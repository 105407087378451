export default {
  props: {
    fileName: {
      type: String,
      default: ''
    }
  },
  data: function data() {
    return {
      progress: 1,
      colors: [{
        color: '#f56c6c',
        percentage: 20
      }, {
        color: '#e6a23c',
        percentage: 40
      }, {
        color: '#6f7ad3',
        percentage: 60
      }, {
        color: '#1989fa',
        percentage: 80
      }, {
        color: '#5cb87a',
        percentage: 100
      }],
      hiveData: {}
    };
  },
  methods: {
    setProgress: function setProgress(progress) {
      this.progress = progress;
    }
  }
};