var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "global-bar"
  }, [_c("div", {
    staticClass: "global-bar-label"
  }, [_vm._v(_vm._s(_vm.fileName))]), _vm._v(" "), _c("el-progress", {
    attrs: {
      "text-inside": true,
      "stroke-width": 16,
      percentage: _vm.progress,
      color: _vm.colors
    }
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };