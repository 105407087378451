import service from '@/utils/request';

//创建文件夹
export var createFolder = function createFolder(data) {
  return service({
    url: "/folderService/createFolder",
    method: 'post',
    data: data
  });
};
export var createLocalFolder = function createLocalFolder(data) {
  return service({
    url: "/folderService/createLocalFolder",
    method: 'post',
    data: data
  });
};

//修改文件
export var updateFolder = function updateFolder(data) {
  return service({
    url: "/folderService/updateFolder",
    method: 'put',
    data: data
  });
};

//getS3BucketList
export var getS3BucketList = function getS3BucketList(params) {
  return service({
    url: "/folderService/getS3BucketList",
    method: 'get',
    params: params
  });
};

//FindFolderName
export var findFolderName = function findFolderName(data) {
  return service({
    url: "/folderService/findFolderName",
    method: 'post',
    data: data
  });
};

//getSubFolderByPid
export var getSubFolderByPid = function getSubFolderByPid(data) {
  return service({
    url: "/folderService/getSubFolderByPid",
    method: 'post',
    data: data
  });
};

//getFolderList
export var getFolderList = function getFolderList(params) {
  return service({
    url: "/folderService/getFolderList",
    method: 'get',
    params: params
  });
};
export var deleteFolder = function deleteFolder(data) {
  return service({
    url: "/folderService/deleteFolder",
    method: 'delete',
    data: data
  });
};

//deleteFolders
export var deleteFolders = function deleteFolders(data) {
  return service({
    url: "/folderService/deleteFolders",
    method: 'delete',
    data: data
  });
};
export var deleteLocalFolder = function deleteLocalFolder(data) {
  return service({
    url: "/folderService/deleteLocalFolder",
    method: 'delete',
    data: data
  });
};
export var deleteLocalFolders = function deleteLocalFolders(data) {
  return service({
    url: "/folderService/deleteLocalFolders",
    method: 'delete',
    data: data
  });
};